import React from 'react';
import BottomNavMsg from './components/Navigation/BottomNavMsg';
import ControllerOnboarding from './components/Onboarding/ControllerOnboarding';
import useOnboarding from './hooks/useOnboarding';
const App = () => {
  const { renderOnboarding } = useOnboarding();

  return (
    <div className="onboarding--react">
      {renderOnboarding()}

      <ControllerOnboarding />

      <BottomNavMsg />
    </div>
  );
};

export default App;
