import React, { useEffect, useState } from 'react';
import useMessages from '../../hooks/useMessages';
import { useOnboardingClick } from '../../hooks/useOnboardingClick';

const BottomNavMsg = () => {
    const { currentMessage, removeMessage, isVisible } = useMessages();
    const [apiData, setApiData] = useState(null);
    const [characterImage, setCharacterImage] = useState(null);
    const selectedCharacter = localStorage.getItem('CrewSelectedCharacter');
    const [isLoading, setIsLoading] = useState(true);
    const { triggerOnboardingClick } = useOnboardingClick();

    useEffect(() => {

        const fetchApiData = async () => {
            setIsLoading(true);
            try {
                const currentLanguage = 
                    typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
                        ? wpmlSettings.currentLanguage
                        : 'default';

                console.log('[BottomNavMsg] Fetching data for language:', currentLanguage);
                
                const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                }

                const data = await response.json();
                console.log('[BottomNavMsg] API Data received:', data?.general_settings?.[selectedCharacter]?.nav);


            const img = data?.general_settings?.[selectedCharacter]?.nav;
            if (img) {
                setCharacterImage(img);
            } else if (!selectedCharacter) {
                setCharacterImage('https://casinocrew.com/wp-content/uploads/2024/11/ana.png');
            }




            } catch (error) {
                console.error('[BottomNavMsg] Error fetching data:', error);
                if (!selectedCharacter) {
                    setCharacterImage('https://casinocrew.com/wp-content/uploads/2024/11/ana.png');
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchApiData();
    }, [selectedCharacter]);

    const handleNextMessage = () => {
        if (currentMessage && typeof currentMessage.onClose === 'function') {
            currentMessage.onClose();
        }
        removeMessage();
    };

    const handleOnboardingClick = () => {
        triggerOnboardingClick();
        removeMessage();
    };

    return (
        <div>
            {isVisible && <div className="onboarding d-block" onClick={handleOnboardingClick}></div>}
            <div className="container-lg bottom-nav">
                <div className="bottom-nav__msg">
                    {isVisible && currentMessage && (
                        <div className={`popup__ctn ${isVisible ? 'visible moveup-anim' : ''}`}>
                            <p>{currentMessage.text}</p>
                            <a onClick={handleNextMessage} className="btn btn--3">
                                {currentMessage.buttonText}
                            </a>
                        </div>
                    )}


                    <div className={`ana-ctn ${isVisible ? 'moveup-anim' : ''}`}>
                        {!isLoading && characterImage && (
                            <img src={characterImage} alt={`${selectedCharacter || 'Character'} image`} />
                        )}
                    </div>
                
                
                </div>
            </div>
        </div>
    );
};

export default BottomNavMsg;
