import { useState, useEffect } from 'react';
import Onboarding1 from '../components/Onboarding/Onboarding1';
import Onboarding2 from '../components/Onboarding/Onboarding2';
import Onboarding3 from '../components/Onboarding/Onboarding3';
import Onboarding5 from '../components/Onboarding/Onboarding5';

const useOnboarding = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(localStorage.getItem('CrewSelectedCharacter'));
  const [selectedCharacterImg, setSelectedCharacterImg] = useState(null);
  const [onboarding1Completed, setOnboarding1Completed] = useState(false);
  const [onboarding2Completed, setOnboarding2Completed] = useState(false);
  const [onboarding3Completed, setOnboarding3Completed] = useState(false);

  useEffect(() => {
    const character = localStorage.getItem('CrewSelectedCharacter');
    const characterImg = localStorage.getItem('CrewSelectedCharacterImg');
    const onboardingCompleted = localStorage.getItem('Onboarding1Completed') === 'true';
    const onboarding2Completed = localStorage.getItem('Onboarding2Completed') === 'true';
    const onboarding3Completed = localStorage.getItem('Onboarding3Completed') === 'true';

    setSelectedCharacter(character);
    setSelectedCharacterImg(characterImg);
    setOnboarding1Completed(onboardingCompleted);
    setOnboarding2Completed(onboarding2Completed);
    setOnboarding3Completed(onboarding3Completed);
  }, []);

  const renderOnboarding = () => {
    if (!selectedCharacter && !onboarding1Completed && localStorage.getItem('CrewSkipOnboarding') !== 'true') {
      return <Onboarding1 />;
    }

    if (selectedCharacter && onboarding1Completed && !onboarding2Completed) {
      return <Onboarding2 characterName={selectedCharacter} />;
    }

    const onboarding3Status = localStorage.getItem('Onboarding3Completed');

    if (
      onboarding2Completed &&
      !onboarding3Completed &&
      onboarding3Status !== 'canceled' &&
      onboarding3Status !== 'skipped'
    ) {
      return <Onboarding3 />;
    }

    if (onboarding3Completed || localStorage.getItem('Onboarding3Completed') === 'skipped') {
      return <Onboarding5 />;
    }

    return null;
  };

  return {
    selectedCharacter,
    selectedCharacterImg,
    onboarding1Completed,
    onboarding2Completed,
    onboarding3Completed,
    renderOnboarding,
  };
};

export default useOnboarding;
