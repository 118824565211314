import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
// Step Components
const Step1 = ({ apiData, handleStartOnboarding, handleSkip }) => (
    <div id="step1" className="modal_content d-block">
        <div className="step1_contnet">
            {/* Render the HTML content safely */}
            {apiData.intro?.intro ? (
                <div dangerouslySetInnerHTML={{ __html: apiData.intro.intro }} />
            ) : (
                <>
                    <h1>{apiData.intro?.title || 'Welcome to Casino Crew'}</h1>
                    <p>{apiData.intro?.description || 'Choose a guide for the best experience or skip for the standard.'}</p>
                </>
            )}
        </div>
        <button onClick={handleStartOnboarding} className="btn btn--1 next-modal">
            {apiData.intro?.start || "LET's see the guide"}
        </button>
        <button onClick={handleSkip} className="btn btn-skip">
            {apiData.intro?.cancel || "I'll have the regular experience >"}
        </button>
    </div>
);

const Step2 = ({ question, currentQuestionIndex, totalQuestions, handleAnswerSelection, handleGoBack }) => (
    <div id="step2" className="modal_content d-block">
        <div onClick={handleGoBack} className="go-back m-3 p-3" >
            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33333 1.5L1 9.5M1 9.5L9.33333 17.5M1 9.5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
        <h1>{question.heading || 'Choose what describes you best'}</h1>
        <div className='q-label'>{question.label}</div>
        <p>{question.text}</p>
        <div className="q-legend">{`${currentQuestionIndex + 1}/${totalQuestions}`}</div>
        <form key={`question-form-${currentQuestionIndex}`}> 
            {question.answers.map((answer, i) => (
                <label className="form-control" key={i}>
                    <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        onClick={() => handleAnswerSelection(answer.value)}
                    />
                    {answer.text}
                </label>
            ))}
        </form>
    </div>
);


const Step3 = ({ apiData, charactersData, handleChooseGuide }) => {
    return (
        <div id="step3" className="d-block">
            <section className="hp-characters-guide">
                <div className="container">
                    <div className="heading">
                        <h2 className="title">{apiData.finalStepTitle || 'Your Map to Casino Adventure – Bonuses and Beyond!'}</h2>
                        <p>{apiData.finalStepDescription || 'Explore your guide to the Casino Crew.'}</p>
                    </div>
                    {/* Integrarea React Splide */}
                    <Splide
                        options={{
                            type: 'loop',
                            perPage: 4,
                            perMove: 1,
                            gap: '1rem',
                            autoplay: true,
                            interval: 5000,
                            pauseOnHover: true,
                            breakpoints: {
                                1200: {
                                    perPage: 2,
                                    autoplay: true,
                                },
                                768: {
                                    perPage: 1,
                                    autoplay: true,
                                },
                            },
                            arrows: true,
                            pagination: true,
                        }}
                        aria-label="Character Selection Slider"
                        className="hp-characters-splide"
                    >
                        {charactersData.map((character, index) => (
                            <SplideSlide key={index}>
                                <div className="character-card-selector">
                                    <div className="card" style={{ backgroundImage: `url(${character.charImg})` }}>
                                        {parseInt(character.match) > 0 && parseInt(character.match) <= 100 && (
                                            <div className="match-ctn">
                                                <div className="match">{character.match}</div>
                                                <div className="bonus">MATCH</div>
                                            </div>
                                        )}
                                        <div className="content">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="name">{character.name}</div>
                                                <div className="role">{character.role}</div>
                                            </div>
                                            <button
                                                className="btn btn--1 ftbn"
                                                onClick={() => handleChooseGuide(character.name)}
                                            >
                                                CHOOSE GUIDE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </section>
        </div>
    );
};

const Onboarding1 = () => {
    const [apiData, setApiData] = useState({});
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [results, setResults] = useState({ alex: 0, finn: 0, ana: 0, james: 0 });
    const [isCharacterSelected, setIsCharacterSelected] = useState(false);
    const [currentStep, setCurrentStep] = useState(null); // Initialize as null
    const [language, setLanguage] = useState('default');
    const [dataLoaded, setDataLoaded] = useState(false);

    const debugMode = true; // Set to false to disable logs

    // Function to control logging
    const log = (message, ...optionalParams) => {
        if (debugMode) {
            console.log(message, ...optionalParams);
        }
    };

    // Fetches onboarding data from API and checks for existing character
    useEffect(() => {

        const currentUrl = window.location.href;
        log('Current URL:', currentUrl);

        // Simple check for WordPress - if pathname is anything other than /
        // if (window.location.pathname !== '/') {
        //     log('Not on homepage, redirecting...');
        //     window.location.href = window.location.origin;
        //     return;
        // }


        const fetchApiData = async () => {
            try {
                const currentLanguage =
                    typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
                        ? wpmlSettings.currentLanguage
                        : 'default';
                setLanguage(currentLanguage);

                log('Fetching API Data with language:', currentLanguage);

                const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
                if (!response.ok) throw new Error('Network response was not OK');

                const data = await response.json();
                log('API Data fetched:', data);
                setApiData(data.onboarding_1);

                // Prepare questions
                const preparedQuestions = data.onboarding_1.cards
                    ? data.onboarding_1.cards.map((card, index) => createQuestion(card, index)).filter(q => q !== null)
                    : [];
                setQuestions(preparedQuestions);

                log('Prepared Questions:', preparedQuestions);

                setDataLoaded(true); // Set dataLoaded to true after data is fetched and processed
            } catch (error) {
                console.error('Fetch operation encountered a problem:', error);
            }
        };




        const checkCharacterSelection = () => {
            const savedCharacter = localStorage.getItem('CrewSelectedCharacter');
            setIsCharacterSelected(!!savedCharacter);

            log('Checking character selection. Is character selected?', !!savedCharacter);

            if (!savedCharacter) {
                // No character selected, show onboarding
                log('No character selected. Starting onboarding.');
                setCurrentStep(1); // Set to step 1 to display the modal after data is loaded
                fetchApiData();
            } else {
                // Character is selected, do not show onboarding
                log('Character already selected:', savedCharacter);
                setCurrentStep(null);
            }
        };

        checkCharacterSelection();
    }, []);

    const createQuestion = (card, index) => {
        log(`Creating question from card at index ${index}:`, card);

        const answers = [];
        const answerKeys = Object.keys(card).filter(key => key.startsWith('answers_'));
        if (answerKeys.length === 0) {
            console.warn('No answers found for card:', card);
            return null;
        }

        answerKeys.forEach(key => {
            const answerData = card[key];
            if (answerData && answerData.answer) {
                answers.push({
                    text: answerData.answer || '',
                    value: {
                        alex: parseInt(answerData.alex) || 0,
                        finn: parseInt(answerData.finn) || 0,
                        ana: parseInt(answerData.ana) || 0,
                        james: parseInt(answerData.james) || 0,
                    },
                });
            }
        });

        if (answers.length === 0) {
            console.warn('No valid answers for card:', card);
            return null;
        }

        const question = {
            label: card.label || `Question ${index + 1}`,
            heading: card.heading || 'Choose what describes you best',
            text: card.question || '',
            answers: answers,
        };

        log('Created question:', question);

        return question;
    };

    // Handlers
    const handleStartOnboarding = () => {
        log('Proceeding to Step 2');
        setCurrentStep(2);
    };

    const handleAnswerSelection = (answerValue) => {
        log('Answer selected with value:', answerValue);

        // Update results with selected answer values
        const newResults = { ...results };
        Object.keys(newResults).forEach((key) => {
            newResults[key] += answerValue[key];
        });
        setResults(newResults);

        log('Updated results:', newResults);

        // Check if more questions are available
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            log('Moving to next question. Current question index:', currentQuestionIndex + 1);
        } else {
            log('No more questions. Proceeding to Step 3');
            setCurrentStep(3); // Move to the final step
        }
    };

    const handleSkip = () => {
        log('Onboarding skipped');
        setIsCharacterSelected(true);
        localStorage.setItem('CrewSkipOnboarding', 'true');
        localStorage.setItem('CrewSkipOnboardingTimestamp', Date.now().toString());
        setCurrentStep(null); // Hide the modal
    };

    const handleChooseGuide = (characterName) => {
        log('Choosing Guide:', characterName);

        // Save selected character to localStorage
        localStorage.setItem('CrewSelectedCharacter', characterName);
        setIsCharacterSelected(true);
        setCurrentStep(null); // Hide the modal

        fetch('/wp-json/onboarding/v1/set-character', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ characterName })
        })
            .then(response => response.json())
            .then(data => console.log('Character stored in PHP session:', data))
            .catch(error => console.error('Error:', error));

        // Update character image in the navigation
        updateCharacterImage(characterName);

        // Initialize Onboarding2 with the selected character
        // new Onboarding2(characterName);
        localStorage.setItem('Onboarding1Completed', 'true');
        window.location.reload();
    };

    const handleGoBack = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            log('Going back to previous question. Current question index:', currentQuestionIndex - 1);
        } else {
            setCurrentStep(1);
            log('Returning to Step 1');
        }
    };

    const updateCharacterImage = (characterName) => {

    };

    // Calculate the match for characters
    const calculateMatch = (score) => {
        // Calculate average based on number of questions
        const averageScore = score / questions.length;
        // Convert to percentage and ensure it doesn't exceed 100
        const match = Math.min(Math.round(averageScore), 100) + '%';
        log(`Calculated match percentage: ${match} for average score: ${averageScore}`);
        return match;
    };

    // Prepare character data when results are updated
    const charactersData = React.useMemo(() => {
        const data = [
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20finn.png',
                match: calculateMatch(results.finn),
                name: 'finn',
                role: 'The Player',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20ana.png',
                match: calculateMatch(results.ana),
                name: 'ana',
                role: 'The Explorer',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20alex.png',
                match: calculateMatch(results.alex),
                name: 'alex',
                role: 'The Newbie',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20james.png',
                match: calculateMatch(results.james),
                name: 'james',
                role: 'The Architect',
            },
        ].sort((a, b) => parseInt(b.match) - parseInt(a.match));

        log('Characters data after sorting:', data);
        return data;
    }, [results]);

    // Render the modal only after data is loaded
    if (!dataLoaded || currentStep === null) {
        return null; // Don't render anything until data is loaded and onboarding is active
    }

    // Render the appropriate step
    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        apiData={apiData}
                        handleStartOnboarding={handleStartOnboarding}
                        handleSkip={handleSkip}
                    />
                );
            case 2:
                return (
                    <Step2
                        question={questions[currentQuestionIndex]}
                        currentQuestionIndex={currentQuestionIndex}
                        totalQuestions={questions.length}
                        handleAnswerSelection={handleAnswerSelection}
                        handleGoBack={handleGoBack}
                    />
                );
            case 3:
                return (
                    <Step3
                        apiData={apiData}
                        charactersData={charactersData}
                        handleChooseGuide={handleChooseGuide}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="onboarding-1 onboarding d-block">
            <div className="modal_ctn">
                {renderCurrentStep()}
            </div>
        </div>
    );
};

export default Onboarding1;



