import React, { useEffect, useState } from 'react';
import useMessages from '../../hooks/useMessages';
import { useOnboardingClick } from '../../hooks/useOnboardingClick';

const DEBUG_MODE = true;

const Onboarding5 = () => {
    const [data, setData] = useState(null);
    const { addMessage, removeMessage, registerOverlayCallback } = useMessages();
    const [currentStepIndices, setCurrentStepIndices] = useState({});
    const [activeMsg, setActiveMsg] = useState(false);
    const [activeObservers, setActiveObservers] = useState(new Set());
    const [activeStep, setActiveStep] = useState(null);
    const { triggerOnboardingClick, addOnboardingClickListener } = useOnboardingClick(() => {

        log('Onboarding clicked');
        if (activeStep && activeMsg) {
            const currentMessageIndex = currentStepIndices[activeStep] || 0;
            const currentStep = data?.steps?.find(step => step.targetClass === activeStep);
            const currentMessage = currentStep?.messages[currentMessageIndex]?.text;

            activeObservers.forEach((observer) => {
                if (observer.targetClass === activeStep) {
                    observer.disconnect();
                }
            });

            log('Onboarding clicked', {
                currentMessage,
                fullStep: currentStep
            });

            updateObserverStatus(activeStep, 'canceled');


            styleElement(document.querySelector(currentStep.targetClass), false);

        }
    });

    const log = (message, ...args) => {
        if (DEBUG_MODE) {
            console.log(`[Onboarding5] ${message}`, ...args);
        }
    };



    const saveObserverToStorage = (selector, timestamp, actionType) => {
        const path = window.location.pathname;
        const allObservers = JSON.parse(localStorage.getItem('onboardingObservers') || '{}');

        if (!allObservers[path]) {
            allObservers[path] = [];
        }

        // Check if action for selector already exists in array
        const existingActionIndex = allObservers[path].findIndex(action => action.selector === selector);

        const actionData = {
            selector,
            timestamp,
            status: 'idle',
            completed: false,
            actionType: actionType
        };

        if (existingActionIndex > -1) {
            // Update existing action
            allObservers[path][existingActionIndex] = actionData;
        } else {
            // Add new action
            allObservers[path].push(actionData);
        }

        localStorage.setItem('onboardingObservers', JSON.stringify(allObservers));
        log(`Saved observer for ${selector} to localStorage with action type: ${actionType} on URL: ${path}`);
    };

    const trackObserver = (observer, selector, actionType) => {
        observer.targetClass = selector;

        setActiveObservers((prev) => {
            const newSet = new Set(prev);
            newSet.add(observer);
            log(`New observer added. Active observers count: ${newSet.size}`);

            // Save to localStorage with actionType
            saveObserverToStorage(selector, Date.now(), actionType);

            return newSet;
        });
    };

    const getObserverOptions = (element) => {
        const elementHeight = element.getBoundingClientRect().height;
        const viewportHeight = window.innerHeight;
        const isLargeElement = elementHeight > viewportHeight;

        log(`Element height check:`, {
            elementHeight,
            viewportHeight,
            isLargeElement
        });

        return {
            threshold: isLargeElement ? [0, 0.1] : 0.5,
            rootMargin: isLargeElement ? '10% 0px -10% 0px' : '-15% 0px -15% 0px'
        };
    };

    const actionHandlers = {
        onenter: (element, step) => {
            log(`Setting up onenter handler for ${step.targetClass}`);

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            handleElementInteraction(element, step, observer);
                        }
                    });
                },
                getObserverOptions(element)
            );

            observer.observe(element);
            trackObserver(observer, step.targetClass, 'onenter');
            return observer;
        },

        onfocus: (element, step) => {
            log(`Setting up onfocus handler for ${step.targetClass}`);

            const delay = parseInt(step.delay) * 1000 || 0;
            let timeoutId = null;
            let counterId = null;
            let isInViewport = false;
            let secondsElapsed = 0;

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && !isInViewport) {
                            isInViewport = true;
                            secondsElapsed = 0;
                            log(`Element ${step.targetClass} is in viewport, starting delay of ${delay}ms`);

                            // Counter pentru logging la fiecare secundă
                            counterId = setInterval(() => {
                                secondsElapsed++;
                                log(`Element has been in viewport for ${secondsElapsed} seconds of ${delay / 1000} required seconds`);
                            }, 1000);

                            timeoutId = setTimeout(() => {
                                if (isInViewport) {
                                    clearInterval(counterId);
                                    log(`Element remained in viewport for entire ${delay}ms, showing message`);
                                    handleElementInteraction(element, step, observer);
                                }
                            }, delay);
                        } else if (!entry.isIntersecting && isInViewport) {
                            isInViewport = false;
                            if (timeoutId) {
                                clearTimeout(timeoutId);
                                clearInterval(counterId);
                                timeoutId = null;
                                counterId = null;
                                log(`Element ${step.targetClass} exited viewport after ${secondsElapsed} seconds, canceling delay timer`);
                            }

                            if (step.disappears_on_exit && activeMsg) {
                                log(`Element ${step.targetClass} exited viewport, removing message and disconnecting observer`);
                                removeMessage();
                                setActiveMsg(false);
                                styleElement(element, false);
                                observer.disconnect();
                                removeObserver(observer);
                            }
                        }
                    });
                },
                getObserverOptions(element)
            );

            observer.observe(element);
            trackObserver(observer, step.targetClass, 'onfocus');
            return observer;
        },

        onscroll: (element, step) => {
            log(`Setting up onscroll handler for ${step.targetClass}`);

            let enterExitCount = 0;
            let timeoutId = null;
            let counterId = null;
            let isInViewport = false;
            let secondsElapsed = 0;
            const requiredEnterExitCount = parseInt(step.enter_exit_count) || 3;

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            if (enterExitCount >= requiredEnterExitCount) {
                                const delay = parseInt(step.delay) * 1000 || 0;
                                isInViewport = true;
                                secondsElapsed = 0;
                                log(`Element ${step.targetClass} has entered viewport ${requiredEnterExitCount} times, starting delay of ${delay}ms`);

                                // Counter pentru logging la fiecare secundă
                                counterId = setInterval(() => {
                                    secondsElapsed++;
                                    log(`Element has been in viewport for ${secondsElapsed} seconds of ${delay / 1000} required seconds`);
                                }, 1000);

                                timeoutId = setTimeout(() => {
                                    if (isInViewport) {
                                        clearInterval(counterId);
                                        log(`Delay passed, showing message for ${step.targetClass}`);
                                        handleElementInteraction(element, step, observer);
                                    }
                                }, delay);
                            }
                        } else {
                            if (timeoutId) {
                                clearTimeout(timeoutId);
                                clearInterval(counterId);
                                timeoutId = null;
                                counterId = null;
                                isInViewport = false;
                                log(`Element ${step.targetClass} exited viewport after ${secondsElapsed} seconds, canceling delay timer`);
                            }
                            enterExitCount++;
                            log(`Element ${step.targetClass} exited viewport, enter/exit count: ${enterExitCount}`);
                        }
                    });
                },
                getObserverOptions(element)
            );

            observer.observe(element);
            trackObserver(observer, step.targetClass, 'onscroll');
            return observer;
        },

        ontextselect: (element, step) => {
            log(`Setting up ontextselect handler for ${step.targetClass}`);

            // Save to localStorage since there's no observer
            saveObserverToStorage(step.targetClass, Date.now(), 'ontextselect');

            const handleTextSelection = () => {
                const selectedText = window.getSelection().toString();
                if (selectedText.includes(step.selected_text)) {
                    log(`Selected text matches: ${selectedText}`);
                    handleElementInteraction(element, step, null); // Pass null for observer as it's not needed here
                }
            };

            element.addEventListener('mouseup', handleTextSelection);

            return () => {
                element.removeEventListener('mouseup', handleTextSelection);
            };
        },
    };

    const updateObserverStatus = (selector, status) => {
        const path = window.location.pathname;
        const allObservers = JSON.parse(localStorage.getItem('onboardingObservers') || '{}');

        if (allObservers[path]) {
            const actions = allObservers[path];
            const actionIndex = actions.findIndex(action => action.selector === selector);
            if (actionIndex > -1) {
                actions[actionIndex].status = status;
                if (status === 'completed' || status === 'canceled') {
                    actions[actionIndex].completed = true;
                }
                localStorage.setItem('onboardingObservers', JSON.stringify(allObservers));
                log(`Updated status for ${selector} to ${status}`);
            } else {
                // Add new action if not found
                const actionData = {
                    selector,
                    timestamp: Date.now(),
                    status: status,
                    completed: status === 'completed' || status === 'canceled',
                    actionType: null
                };
                actions.push(actionData);
                localStorage.setItem('onboardingObservers', JSON.stringify(allObservers));
                log(`Added new action for ${selector} with status ${status}`);
            }
        } else {
            // Create new entry for the path
            allObservers[path] = [{
                selector,
                timestamp: Date.now(),
                status: status,
                completed: status === 'completed' || status === 'canceled',
                actionType: null
            }];
            localStorage.setItem('onboardingObservers', JSON.stringify(allObservers));
            log(`Created new observers entry for ${path} and selector ${selector}`);
        }
    };

    const handleElementInteraction = (element, step, observer) => {
        const currentMessageIndex = currentStepIndices[step.targetClass] || 0;
        log(`Element interaction. Current message index: ${currentMessageIndex}/${step.messages.length - 1}`);

        const message = step.messages[currentMessageIndex];
        if (message && !activeMsg) {
            updateObserverStatus(step.targetClass, 'active');
            showMessage(element, step, currentMessageIndex, observer);
        }
    };

    const showMessage = (element, step, currentMessageIndex, observer) => {
        log(`Showing message ${currentMessageIndex + 1} of ${step.messages.length}`);
        setActiveMsg(true);
        styleElement(element, true);
        setActiveStep(step.targetClass);

        setTimeout(() => {
            const message = step.messages[currentMessageIndex];
            addMessage({
                id: `${step.targetClass}-message-${currentMessageIndex}`,
                text: message.text,
                buttonText: message.buttonText,
                onClose: () => handleMessageClose(element, step, currentMessageIndex, observer),
            });
        }, 0);
    };

    const handleMessageClose = (element, step, currentMessageIndex, observer) => {
        if (currentMessageIndex + 1 < step.messages.length) {
            log(`Preparing next message ${currentMessageIndex + 2} of ${step.messages.length}`);
            prepareNextMessage(element, step, currentMessageIndex, observer);
        } else {
            log(`Finishing message sequence for ${step.targetClass}`);
            finishMessageSequence(element, observer, step);
        }
    };

    const prepareNextMessage = (element, step, currentMessageIndex, observer) => {
        setCurrentStepIndices((prev) => ({
            ...prev,
            [step.targetClass]: currentMessageIndex + 1,
        }));
        setActiveMsg(false);

        setTimeout(() => {
            showMessage(element, step, currentMessageIndex + 1, observer);
        }, 0);
    };

    const finishMessageSequence = (element, observer, step) => {
        setActiveMsg(false);
        styleElement(element, false);
        setActiveStep(null);
        registerOverlayCallback(null);
        if (observer) {
            updateObserverStatus(step.targetClass, 'completed');
            observer.disconnect();
            removeObserver(observer);
        } else {
            updateObserverStatus(step.targetClass, 'completed');
        }
    };

    const styleElement = (element, isActive) => {
        if (isActive) {
            element.style.outline = '2px solid #fff';
            element.style.position = 'relative';
            element.style.zIndex = '100';
        } else {
            element.style.outline = '';
            element.style.position = '';
            element.style.zIndex = '';
        }
    };

    const removeObserver = (observer) => {
        setActiveObservers((prev) => {
            const newSet = new Set(prev);
            newSet.delete(observer);
            log(`Observer removed. Active observers count: ${newSet.size}`);
            return newSet;
        });
    };

    const isUrlMatch = (pageUrls, currentUrl) => {
        log(`Checking URL match for current URL: ${currentUrl}`);

        // Verificăm dacă avem un array de pagini
        if (Array.isArray(pageUrls)) {
            return pageUrls.some(page => {
                const patternUrl = page.on_page.url;
                log(`Checking against pattern: ${patternUrl}`);

                // Check if the pattern ends with /*
                if (patternUrl.endsWith('/*')) {
                    log(`Pattern ends with /*`);
                    // Remove the /* and check if the current URL starts with the pattern base
                    const basePattern = patternUrl.slice(0, -2);
                    const isMatch = currentUrl.startsWith(basePattern);
                    log(`Base pattern: ${basePattern}, isMatch: ${isMatch}`);
                    return isMatch;
                }
                // If no wildcard, do exact match
                const isMatch = patternUrl === currentUrl;
                log(`Exact match check: ${isMatch}`);
                return isMatch;
            });
        }

        // Backward compatibility pentru vechiul format
        const patternUrl = pageUrls?.url || pageUrls;
        if (patternUrl.endsWith('/*')) {
            const basePattern = patternUrl.slice(0, -2);
            return currentUrl.startsWith(basePattern);
        }
        return patternUrl === currentUrl;
    };

    const checkObserverStatus = (selector) => {
        const path = window.location.pathname;
        const allObservers = JSON.parse(localStorage.getItem('onboardingObservers') || '{}');
        const actions = allObservers[path];
        if (actions) {
            const action = actions.find(action => action.selector === selector);
            if (action) {
                return {
                    status: action.status,
                    completed: action.completed || action.status === 'canceled' || action.status === 'complete'
                };
            }
        }
        return {
            status: null,
            completed: false
        };
    };


    const handleStep = (step) => {
        const observerStatus = checkObserverStatus(step.targetClass);

        if (observerStatus.completed) {
            log(`Skipping observer for ${step.targetClass} - already completed`);
            return;
        }

        const targetElements = document.querySelectorAll(step.targetClass);
        log(`Found ${targetElements.length} elements for selector: ${step.targetClass}`);

        targetElements.forEach((element, index) => {
            log(`Creating new observer for ${step.targetClass}`);

            const handler = actionHandlers[step.action_type];
            if (handler) {
                const observer = handler(element, step);
                if (observerStatus.status === 'active') {
                    log(`Disconnecting existing active observer for ${step.targetClass}`);
                    if (observer && observer.disconnect) {
                        observer.disconnect();
                        removeObserver(observer);
                    }
                }
            } else {
                log(`Unknown action type: ${step.action_type}`);
            }
        });
    };

    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const currentLanguage =
                    typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
                        ? wpmlSettings.currentLanguage
                        : 'default';

                log('Fetching API data with language:', currentLanguage);

                const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
                if (!response.ok) throw new Error('Network response was not OK');

                const result = await response.json();
                const selectedCharacter = localStorage.getItem('CrewSelectedCharacter');
                log('Onboarding data loaded for character:', selectedCharacter);
                const onboardingData = result.onboarding_5[selectedCharacter];
                setData(onboardingData);
                log('Onboarding data:', onboardingData);
                onboardingData.steps.forEach((step, index) => {
                    if (isUrlMatch(step.pages, window.location.href)) {
                        log(`Processing step ${index + 1}:`, step);

                        if (step.targetClass) {
                            handleStep(step);
                        }
                    } else {
                        log(`Skipping step ${index + 1} - URL mismatch:`, {
                            expected_urls: step.on_page,
                            current_url: window.location.href,
                        });
                    }
                });
            } catch (error) {
                log('Error loading onboarding data:', error);
            }
        };

        fetchApiData();

        return () => {
            log('Cleaning up component...');
            activeObservers.forEach((observer) => {
                observer.disconnect();
                log('Observer disconnected during cleanup');
            });
        };
    }, []);

    return (
        <></>
    );
};

export default Onboarding5;
