import { useEffect } from 'react';

export const ONBOARDING_CLICK_EVENT = 'onboardingClick';

export const useOnboardingClick = (onClickCallback) => {
  const triggerOnboardingClick = () => {
    const event = new CustomEvent(ONBOARDING_CLICK_EVENT);
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (onClickCallback) {
      window.addEventListener(ONBOARDING_CLICK_EVENT, onClickCallback);
      return () => window.removeEventListener(ONBOARDING_CLICK_EVENT, onClickCallback);
    }
  }, [onClickCallback]);

  return { triggerOnboardingClick };
};
