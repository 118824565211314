import React, { useState, useEffect } from 'react';
import '@splidejs/react-splide/css';

const Onboarding2 = ({ characterName }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [modalVisible, setModalVisible] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [language, setLanguage] = useState('default');
  const debugMode = false;

  // Method to control logging
  const log = (message, ...optionalParams) => {
    if (debugMode) {
      console.log(message, ...optionalParams);
    }
  };

  useEffect(() => {
    if (!characterName) {
      console.error('Character name is required to initialize Onboarding2.');
      return;
    }
    log('Onboarding2 initialized with character:', characterName);
  }, [characterName]);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const currentLanguage =
          typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
            ? wpmlSettings.currentLanguage
            : 'default';
        setLanguage(currentLanguage);

        log('Fetching API Data with language:', currentLanguage);

        const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
        if (!response.ok) throw new Error('Network response was not OK');

        const data = await response.json();
        log('API Data fetched:', data);
        log(characterName);

        // Store the onboarding steps
        setApiData(data.onboarding_2 || []);
      } catch (error) {
        console.error('Fetch operation encountered a problem:', error);
      }
    };

    fetchApiData();
  }, []);

  const handleNext = () => {
    if (currentStep < apiData.length - 1) {
      setCurrentStep(currentStep + 1);
      log('User clicked the "next-modal" button.');
    } else {
      finishOnboarding();
    }
  };

  const handleSkip = () => {
    localStorage.setItem('Onboarding2Completed', 'true');
    localStorage.setItem('Onboarding3Completed', 'skipped');
    finishOnboarding();
  };

  const finishOnboarding = () => {
    localStorage.setItem('Onboarding2Completed', 'true');
    window.location.reload();
    setModalVisible(false);
    log('User has finished Onboarding2.');
    // Perform any additional actions upon completion
  };

  return (
    <div className="">
      {modalVisible && (
        <div className="onboarding-2 onboarding" style={{ display: 'block' }}>
          <div className="modal_ctn">
            {apiData.length > 0 ? (
              apiData[currentStep] && (
                <div id={`step${currentStep}`} className="modal_content">
                  {console.log(apiData[currentStep])}
                  {/* Extract character-specific data */}
                  {(() => {
                    const stepData = apiData[currentStep];
                    const characterData = stepData.character_cards[characterName] || {};
                    const { cancel, image, start, text } = characterData; 


                    console.log(characterData);
                    console.log(text);
                    console.log(image);
                    console.log(start);
                    console.log(cancel);

                    


                    return (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: characterData.text || stepData.title }}></div>
                        <div className="image-ctn">
                          <img
                            src={characterData.image || stepData.image}
                            alt={characterData.title || stepData.title}
                          />
                        </div>
                        <p>{characterData.tex || stepData.content}</p>

                        {/* Next or Finish button */}
                        {currentStep < apiData.length - 1 ? (
                          <button className="btn btn--1 next-modal" onClick={handleNext}>
                            {characterData.start || 'Next'}
                          </button>
                        ) : (
                          <>
                            {characterData.start ? (
                              <button className="btn btn--1 finish-modal" onClick={finishOnboarding}>
                                {characterData.start || 'Finish'}
                              </button>
                            ) : (
                              <span onClick={() => {
                                localStorage.setItem('Onboarding2Completed', 'true');
                                window.location.reload();
                              }}>
                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.52155 15.7203L16.071 15.956M16.071 15.956L15.8353 4.40657M16.071 15.956L1.92883 1.81385" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                        {characterData.cancel && (
                          <button className="btn btn-skip" onClick={handleSkip}>
                            {characterData.cancel}
                          </button>
                        )}
                      </>
                    );
                  })()}
                </div>
              )
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding2;
