import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MessagesProvider } from './context/MessagesContext'; // Importăm MessagesProvider

document.addEventListener('DOMContentLoaded', function() {
    const dynamicElement = document.getElementById('onboarding--react');
    if (dynamicElement) {
        ReactDOM.render(
            <MessagesProvider>
                <App />
            </MessagesProvider>,
            dynamicElement
        );
    }
});
