import React, { createContext, useState, useCallback } from 'react';

export const MessagesContext = createContext();

export const MessagesProvider = ({ children }) => {
    const [currentMessage, setCurrentMessage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [closeMethod, setCloseMethod] = useState(null);
    const [messages, setMessages] = useState([]);
    const [overlayCallback, setOverlayCallback] = useState(null);

    const registerOverlayCallback = (callback) => {
        setOverlayCallback(callback);
    };

    const onOverlayClick = () => {
        if (overlayCallback) {
            overlayCallback();
        }
    };

    // Funcție pentru a adăuga un mesaj (înlocuiește orice mesaj existent)
    const addMessage = useCallback((message) => {
        setCurrentMessage(message);
        setIsVisible(true);
    }, []);

    // Funcție pentru a elimina mesajul curent
    const removeMessage = useCallback((method = 'overlay') => {
        setCloseMethod(method);
        setCurrentMessage(null);
        setIsVisible(false);
    }, []);

    const value = {
        currentMessage,
        addMessage,
        removeMessage,
        isVisible,
        closeMethod,
        registerOverlayCallback,
        onOverlayClick,
    };

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    );
};
