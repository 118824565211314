import React, { useState, useEffect } from 'react';
import useMessages from '../../hooks/useMessages';

const ControllerOnboarding = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedCharacterImg, setSelectedCharacterImg] = useState(null);
  const [onboarding1Completed, setOnboarding1Completed] = useState(false);
  const [onboarding2Completed, setOnboarding2Completed] = useState(false);
  const [onboarding3Completed, setOnboarding3Completed] = useState(false);
  const [onboarding5Completed, setOnboarding5Completed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [stepsDataFromApi, setStepsDataFromApi] = useState([]);

  const { addMessage, messagesQueue, isVisible: isMessageVisible } = useMessages();

  useEffect(() => {
    const character = localStorage.getItem('CrewSelectedCharacter');
    const characterImg = localStorage.getItem('CrewSelectedCharacterImg');
    const onboarding1 = localStorage.getItem('Onboarding1Completed') === 'true';
    const onboarding2 = localStorage.getItem('Onboarding2Completed') === 'true';
    const onboarding3 = localStorage.getItem('Onboarding3Completed') === 'true';
    const onboarding5 = localStorage.getItem('Onboarding5Completed') === 'true';

    setSelectedCharacter(character);
    setSelectedCharacterImg(characterImg);
    setOnboarding1Completed(onboarding1);
    setOnboarding2Completed(onboarding2);
    setOnboarding3Completed(onboarding3);
    setOnboarding5Completed(onboarding5);
  }, []);

  const getOnboardingStatus = () => {
    const status = localStorage.getItem('Onboarding3Completed');
    if (status === 'skipped') return 'Skipped';
    if (status === 'true') return 'Completed';
    if (status === 'canceled') return 'Canceled';
    return 'Pending';
  };


  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const currentLanguage =
          typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
            ? wpmlSettings.currentLanguage
            : 'default';

        const selectedCharacter = localStorage.getItem('CrewSelectedCharacter') || 'default';

        const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
        if (!response.ok) throw new Error('Network response was not OK');

        const data = await response.json();
        const pages = data.onboarding_3[selectedCharacter]?.page || [];
        setStepsDataFromApi(pages);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchApiData();
  }, [selectedCharacter]);

  const handleSayHello = () => {
    addMessage({
      id: 'Hello, how are you?',
      text: 'Hello!',
      buttonText: 'Close',
      onClose: () => {
        console.log('Close button was clicked!');
      },
    });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const scrollToElement = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Opțional: evidențiem elementul temporar
      element.style.transition = 'outline 0.3s';
      element.style.outline = '2px solid #007bff';
      setTimeout(() => {
        element.style.outline = 'none';
      }, 2000);
    } else {
      console.log(`Element with selector "${selector}" not found`);
    }
  };

  const changeCharacter = (character) => {
    localStorage.setItem('CrewSelectedCharacter', character);
    window.location.reload();
  };

  return (
    <div className='controller-onboarding'>
      {isVisible && selectedCharacter && (
        <div className="container mt-4 p-4 border rounded shadow-sm bg-white p-2" style={{ maxHeight: '80vh', overflow: 'scroll' }}>

          {/* Debug Actions */}
          <div className="mb-3">
            <h6>Debug Actions:</h6>
            <div className="d-flex gap-2 flex-wrap">
              {/* Storage Actions */}
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  localStorage.clear();
                  console.log('Cleared all localStorage');
                  window.location.reload();
                }}
              >
                Clear All LocalStorage
              </button>
              <button
                className="btn btn-warning btn-sm"
                onClick={() => {
                  localStorage.removeItem('onboardingObservers');
                  console.log('Cleared onboardingObservers from localStorage');
                  window.location.reload();
                }}
              >
                Clear Observers
              </button>
              <button
                className="btn btn-info btn-sm"
                onClick={() => {
                  const observers = JSON.parse(localStorage.getItem('onboardingObservers') || '{}');
                  console.log('Current onboardingObservers:', observers);
                }}
              >
                Log Observers
              </button>

              {/* Test Actions */}
              <button onClick={handleSayHello} className="btn btn-primary btn-sm">
                Say Hello
              </button>

              {/* Reset Actions */}
              <button onClick={() => {
                localStorage.removeItem('Onboarding3Completed');

                const onboardingKeys = Object.keys(localStorage).filter(key =>
                  key === 'onboarding_currentPageIndex' ||
                  key === 'onboarding_currentStepIndex' ||
                  key.match(/^onboarding_page_\d+_completed$/)
                );

                onboardingKeys.forEach(key => {
                  localStorage.removeItem(key);
                  console.log('Removed key:', key);
                });

                window.location.reload();
              }} className="btn btn-danger btn-sm">
                Reset Onboarding 3
              </button>

              <button onClick={() => {
                localStorage.removeItem('Onboarding1Completed');
                console.log('Removed key: Onboarding1Completed');
                window.location.reload();
              }} className="btn btn-danger btn-sm">
                Reset Onboarding 1
              </button>

              <button onClick={() => {
                localStorage.removeItem('Onboarding2Completed');
                console.log('Removed key: Onboarding2Completed');
                window.location.reload();
              }} className="btn btn-danger btn-sm">
                Reset Onboarding 2
              </button>

              {/* Set Actions */}
              <button onClick={() => {
                localStorage.setItem('Onboarding1Completed', 'true');
                console.log('Set key: Onboarding1Completed to true');
                window.location.reload();
              }} className="btn btn-success btn-sm">
                Complete Onboarding 1
              </button>

              <button onClick={() => {
                localStorage.setItem('Onboarding2Completed', 'true');
                console.log('Set key: Onboarding2Completed to true');
                window.location.reload();
              }} className="btn btn-success btn-sm">
                Complete Onboarding 2
              </button>

              <button onClick={() => {
                localStorage.setItem('Onboarding3Completed', 'true');
                console.log('Set key: Onboarding3Completed to true');
                window.location.reload();
              }} className="btn btn-success btn-sm">
                Complete Onboarding 3
              </button>

              {/* Change Character Actions */}
              <button onClick={() => changeCharacter('alex')} className="btn btn-secondary btn-sm">
                Change to Alex
              </button>
              <button onClick={() => changeCharacter('james')} className="btn btn-secondary btn-sm">
                Change to James
              </button>
              <button onClick={() => changeCharacter('finn')} className="btn btn-secondary btn-sm">
                Change to Finn
              </button>
              <button onClick={() => changeCharacter('ana')} className="btn btn-secondary btn-sm">
                Change to Ana
              </button>
            </div>
          </div>

          <h4>Selected Character: {selectedCharacter}</h4>


          {/* Onboarding Status with Visual Indicators */}
          <div className="mb-4">
            <h5>Onboarding Status:</h5>
            <div className="list-group">
              <div className={`list-group-item ${onboarding1Completed ? 'list-group-item-success' : 'list-group-item-warning'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Onboarding 1</span>
                  <span className={`badge ${onboarding1Completed ? 'bg-success' : 'bg-warning'}`}>
                    {onboarding1Completed ? 'Completed' : 'Pending'}
                  </span>
                </div>
              </div>
              <div className={`list-group-item ${onboarding2Completed ? 'list-group-item-success' : 'list-group-item-warning'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Onboarding 2</span>
                  <span className={`badge ${onboarding2Completed ? 'bg-success' : 'bg-warning'}`}>
                    {onboarding2Completed ? 'Completed' : 'Pending'}
                  </span>
                </div>
              </div>
              <div className={`list-group-item ${onboarding3Completed ? 'list-group-item-success' : 'list-group-item-warning'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Onboarding 3</span>
                  <span className={`badge ${onboarding3Completed ? 'bg-success' : 'bg-warning'}`}>
                    {getOnboardingStatus()}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Onboarding 3 Detailed Progress */}
          <div className="mb-4">
            <h5>Onboarding 3 Progress:</h5>
            <div className="card">
              <div className="card-body">

                <div className="mb-3">
                  <h6>All Pages:</h6>
                  <div className="list-group">
                    {stepsDataFromApi.map((page, pageIndex) => {
                      const isCompleted = localStorage.getItem(`onboarding_page_${pageIndex}_completed`) === 'true';
                      const isCurrentPage = parseInt(localStorage.getItem('onboarding_currentPageIndex') || '0') === pageIndex;

                      return (
                        <div key={pageIndex} className="list-group-item">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>Page {pageIndex + 1}</span>
                            <div>
                              {isCurrentPage && <span className="badge bg-primary me-2">Current</span>}
                              <span className={`badge ${isCompleted ? 'bg-success' : 'bg-secondary'}`}>
                                {isCompleted ? 'Completed' : 'Not Started'}
                              </span>
                            </div>
                          </div>

                          <div className="small">
                            <div className="text-muted mb-1">URL: {page.on_page?.url || 'N/A'}</div>
                            <div>
                              <strong>Steps:</strong>
                              {page.steps?.map((step, stepIndex) => {
                                const isCurrentStep = isCurrentPage &&
                                  parseInt(localStorage.getItem('onboarding_currentStepIndex') || '0') === stepIndex;

                                return (
                                  <div key={stepIndex}
                                    className={`ms-2 border-start ps-2 mt-1 ${isCurrentStep ? 'bg-light' : ''}`}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span>Step {stepIndex + 1}</span>
                                      <div>
                                        {isCurrentStep && <span className="badge bg-warning me-2">Current</span>}
                                        <span className="badge bg-info">{step.targetClass || 'no-target'}</span>
                                      </div>
                                    </div>
                                    <div className="text-muted small">
                                      {step.messages?.map((message, messageIndex) => {
                                        const isCurrentMessage = isCurrentStep &&
                                          parseInt(localStorage.getItem('onboarding_currentMessageIndex') || '0') === messageIndex;

                                        return (
                                          <div key={messageIndex}
                                            className={`border-start border-info ps-2 mb-1 ${isCurrentMessage ? 'bg-light' : ''}`}>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>Message {messageIndex + 1}: {message.text?.substring(0, 50)}...</div>
                                              {isCurrentMessage && <span className="badge bg-warning me-2">Current Message</span>}
                                            </div>
                                            <div>Button: {message.buttonText || 'N/A'}</div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Onboarding 5 Panel */}
          <div className="mb-4">
            <h5>Onboarding 5 Status:</h5>
            <div className="card">
              <div className="card-body">

                {/* Observers Status */}
                <div className="mb-3">
                  <h6>Observers Status:</h6>
                  {(() => {
                    const observers = JSON.parse(localStorage.getItem('onboardingObservers') || '{}');
                    return (
                      <div className="list-group">
                        {Object.entries(observers).flatMap(([path, actions]) =>
                          actions.map((action, index) => (
                            <div key={`${path}-${index}`} className="list-group-item">
                              <div className="d-flex justify-content-between align-items-center mb-1">
                                <span className="text-truncate" style={{ maxWidth: '200px' }} title={action.selector}>
                                  {action.selector}
                                </span>
                                <div className="d-flex gap-2 align-items-center">
                                  <span className="badge bg-info">
                                    {action.actionType || 'unknown'}
                                  </span>
                                  <span className={`badge ${action.status === 'completed' ? 'bg-success' :
                                      action.status === 'canceled' ? 'bg-danger' :
                                        action.status === 'active' ? 'bg-primary' :
                                          'bg-secondary'
                                    }`}>
                                    {action.status}
                                  </span>
                                  {action.completed &&
                                    <span className="badge bg-success">Completed</span>
                                  }
                                </div>
                              </div>
                              <div className="small text-muted">
                                Last updated: {new Date(action.timestamp).toLocaleString()}
                              </div>
                              <div className="small text-muted">
                                URL: {path || 'N/A'}
                              </div>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => scrollToElement(action.selector)}
                              >
                                Jump to
                              </button>
                            </div>
                          ))
                        )}
                      </div>
                    );
                  })()}
                </div>

              </div>
            </div>
          </div>


        </div>
      )}

      { localStorage.getItem('CrewSkipOnboarding') === 'true' && (
      <button
      className="btn btn-danger btn-sm"
      onClick={() => {
        localStorage.removeItem('CrewSkipOnboarding');
        console.log('Removed key: CrewSkipOnboarding');
        window.location.reload();
      }}
    >
      Reset Skip Onboarding
    </button>
      )}
      <div className="d-flex gap-2 mt-2">
        <button onClick={toggleVisibility} className="btn btn-primary">
          Onboarding
        </button>
      </div>
    </div>
  );
};

export default ControllerOnboarding;