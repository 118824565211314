import React, { useState, useEffect } from 'react';
import useMessages from '../../hooks/useMessages';
import { useOnboardingClick } from '../../hooks/useOnboardingClick';

// Funcție pentru generarea unui UUID simplu
const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

// Adăugăm DEBUG_MODE la început
const DEBUG_MODE = true;

// Adăugăm funcția helper pentru logging
const log = (message, ...args) => {
    if (DEBUG_MODE) {
        console.log(message, ...args);
    }
};

const Onboarding3 = () => {
    const { addMessage } = useMessages();
    const [stepsDataFromApi, setStepsDataFromApi] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(() => {
        const savedPage = localStorage.getItem('onboarding_currentPageIndex');
        return savedPage !== null ? parseInt(savedPage, 10) : 0;
    });
    const [currentStepIndex, setCurrentStepIndex] = useState(() => {
        const savedStep = localStorage.getItem('onboarding_currentStepIndex');
        return savedStep !== null ? parseInt(savedStep, 10) : 0;
    });
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0); // New state for message index

    // Add useOnboardingClick hook
    const { triggerOnboardingClick } = useOnboardingClick(() => {
        log('[Onboarding3] Modal clicked');
        localStorage.setItem('Onboarding3Completed', 'canceled');
    });

    // Preia datele din API la montare
    useEffect(() => {
        const fetchApiData = async () => {
            try {
                const currentLanguage =
                    typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
                        ? wpmlSettings.currentLanguage
                        : 'default';

                const selectedCharacter = localStorage.getItem('CrewSelectedCharacter') || 'default';

                log('[fetchApiData] Începe fetch-ul cu:', {
                    language: currentLanguage,
                    character: selectedCharacter
                });

                const response = await fetch(`/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`);
                if (!response.ok) throw new Error('Network response was not OK');

                const data = await response.json();
                log('[fetchApiData] Date primite de la API:', data);

                const pages = data.onboarding_3[selectedCharacter]?.page || [];

                // Include toate paginile și marchează-le ca completate sau nu
                const allPages = pages.map((page, pageIndex) => {
                    const onboardingStepKey = `onboarding_page_${pageIndex}_completed`;
                    const isCompleted = localStorage.getItem(onboardingStepKey) === 'true';

                    const updatedSteps = page.steps.map(step => ({
                        ...step,
                        id: generateUUID(),
                        pageUrl: page.on_page.url
                    }));

                    return {
                        ...page,
                        steps: updatedSteps,
                        pageIndex: pageIndex,
                        isCompleted: isCompleted
                    };
                });

                setStepsDataFromApi(allPages);
                log('[fetchApiData] Paginile setate:', allPages);
                setIsFetching(false);
            } catch (error) {
                log('[fetchApiData] EROARE:', error);
                setIsFetching(false);
            }
        };

        fetchApiData();
    }, []);

    // Gestionarea pasului curent și afișarea mesajelor
    useEffect(() => {
        if (isFetching || stepsDataFromApi.length === 0) {
            return;
        }

        if (currentPageIndex >= stepsDataFromApi.length) {
            log('[Completion] Onboarding finalizat, setare localStorage');
            localStorage.setItem('Onboarding3Completed', 'true');
            window.location.reload();
            return;
        }

        const currentPage = stepsDataFromApi[currentPageIndex];
        if (!currentPage) {
            log('[Onboarding] Nicio pagină curentă găsită.');
            return;
        }

        // Verifică dacă pagina este deja completată
        if (currentPage.isCompleted) {
            log(`[Onboarding] Pagina ${currentPage.pageIndex} este deja completată. Trec la următoarea pagină.`);
            completePageOnboarding(currentPage, true); // Trecem peste pagina completată
            return;
        }

        const currentUrl = window.location.href;
        const targetUrl = currentPage.on_page.url;

        if (currentUrl.trim() !== targetUrl.trim()) {
            log(`[Navigation] Navigare de la ${currentUrl} la ${targetUrl}`);
            window.location.href = targetUrl;
            return;
        }

        const currentStep = currentPage.steps[currentStepIndex];
        if (!currentStep) {
            log('[Onboarding] Nu există step curent.');
            completePageOnboarding(currentPage);
            return;
        }

        const currentMessage = currentStep.messages[currentMessageIndex]; // Get current message

        if (!currentMessage) {
            log('[Onboarding] Nu există mesaj curent.');
            completePageOnboarding(currentPage);
            return;
        }

        // Afișăm mesajul de onboarding pentru pasul curent
        displayStepMessage(currentMessage, currentPage, currentStepIndex);
    }, [isFetching, stepsDataFromApi, currentPageIndex, currentStepIndex, currentMessageIndex]); // Added currentMessageIndex as dependency

    // Funcție pentru afișarea mesajului de onboarding
    const displayStepMessage = (message, page, stepIndex) => {
        if (!message) return;

        console.log('[DisplayStepMessage] Afișare mesaj:', message);

        if (!message.text || !message.buttonText) {
            log('[StepManager] Mesaj invalid sau incomplet:', message);
            handleStepClose(page, null, stepIndex, true); // Marchez pasul ca fiind completat
            return;
        }

        const onCloseCallback = () => handleMessageClose(page, stepIndex);

        const messageConfig = {
            id: `${page.steps[stepIndex].id}-${currentMessageIndex}`,
            text: message.text,
            buttonText: message.buttonText,
            onClose: onCloseCallback,
            onClick: triggerOnboardingClick, // Add click handler here
        };

        addMessage(messageConfig);

        if (page.steps[stepIndex].targetClass && page.steps[stepIndex].targetClass !== 'no') {
            scrollToElement(page.steps[stepIndex]);
        } else {
            log(`[DisplayMessage] Pasul ${page.steps[stepIndex].id} nu necesită scroll.`);
        }
    };

    // Funcție pentru scroll la elementul țintă
    const scrollToElement = (step) => {
        const element = document.querySelector(step.targetClass);
        log('[ScrollToElement] Încercare scroll la:', {
            targetClass: step.targetClass,
            elementFound: !!element
        });

        if (element) {
            setTimeout(() => {
                const rect = element.getBoundingClientRect();
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const elementTop = rect.top + scrollTop;
                const windowHeight = window.innerHeight;
                const elementHeight = rect.height;

                // Calculăm poziția astfel încât elementul să fie centrat vertical
                const centerPosition = elementTop - (windowHeight / 2) + (elementHeight / 2);

                element.classList.add('onboarding_3');

                window.scrollTo({
                    top: centerPosition,
                    behavior: 'smooth'
                });

                element.style.outline = '2px solid #fff';
                element.style.position = 'relative';
                element.style.zIndex = '100';
            }, 100);
        } else {
            console.warn(`Elementul cu selectorul ${step.targetClass} nu a fost găsit.`);
            // Încearcă din nou după 500ms
            setTimeout(() => {
                log('[ScrollToElement] Reîncercare scroll la:', step.targetClass);
                const retryElement = document.querySelector(step.targetClass);
                if (retryElement) {
                    const rect = retryElement.getBoundingClientRect();
                    const scrollTop = window.scrollY || document.documentElement.scrollTop;
                    const elementTop = rect.top + scrollTop;
                    const windowHeight = window.innerHeight;
                    const elementHeight = rect.height;

                    const centerPosition = elementTop - (windowHeight / 2) + (elementHeight / 2);

                    retryElement.classList.add('onboarding_3');

                    window.scrollTo({
                        top: centerPosition,
                        behavior: 'smooth'
                    });

                    retryElement.style.outline = '2px solid #fff';
                    retryElement.style.position = 'relative';
                    retryElement.style.zIndex = '100';
                } else {
                    console.error(`Elementul cu selectorul ${step.targetClass} nu a fost găsit la a doua încercare.`);
                }
            }, 500);
        }
    };

    // Funcție pentru gestionarea închiderii unui mesaj
    const handleMessageClose = (page, stepIndex) => {
        const currentStep = page.steps[stepIndex];
        const totalMessages = currentStep.messages.length;

        if (currentMessageIndex + 1 < totalMessages) {
            // Avansează la următorul mesaj în cadrul aceluiași pas
            const nextMessageIndex = currentMessageIndex + 1;
            setCurrentMessageIndex(nextMessageIndex);
            localStorage.setItem('onboarding_currentMessageIndex', nextMessageIndex);
            log(`[MessageClose] Avansează la mesajul index ${nextMessageIndex} din pasul ${stepIndex}`);
        } else {
            // Toate mesajele din pas au fost afișate, trecem la următorul pas
            setCurrentMessageIndex(0); // Reset Message Index for next step
            localStorage.setItem('onboarding_currentMessageIndex', '0');
            log('[MessageClose] Toate mesajele din pas au fost afișate. Trec la următorul pas.');
            handleStepClose(page, currentStep, stepIndex);
        }
    };

    // Funcție pentru gestionarea închiderii unui pas
    const handleStepClose = (page, step, stepIndex, skip = false) => {
        if (stepIndex === null && !skip) {
            log('[MessageClose] Pasul invalid sau incomplet.');
            return;
        }

        log('[MessageClose] Închidere mesaj:', {
            stepId: step ? step.id : 'N/A',
            stepIndex: stepIndex !== null ? stepIndex : 'N/A',
            targetClass: step ? step.targetClass : 'N/A'
        });

        // Resetează stilurile elementului, dacă este cazul
        if (step && step.targetClass !== 'no') {
            const element = document.querySelector(step.targetClass);
            if (element) {
                element.style.outline = '';
                element.style.position = '';
                element.style.zIndex = '';
            } else {
                console.warn(`Elementul cu selectorul ${step.targetClass} nu a fost găsit sau nu necesită reset.`);
            }
        }

        if (!skip) {
            // Avansează la următorul pas
            const nextStepIndex = stepIndex + 1;
            const pageSteps = page.steps.length;

            if (nextStepIndex < pageSteps) {
                setCurrentStepIndex(nextStepIndex);
                localStorage.setItem('onboarding_currentStepIndex', nextStepIndex);
            } else {
                // Finalizează onboarding pentru această pagină
                completePageOnboarding(page);
            }
        } else {
            // Dacă pasul a fost marcat ca invalid, sărim peste el
            const nextStepIndex = stepIndex + 1;
            const pageSteps = page.steps.length;

            if (nextStepIndex < pageSteps) {
                setCurrentStepIndex(nextStepIndex);
                localStorage.setItem('onboarding_currentStepIndex', nextStepIndex);
            } else {
                // Finalizează onboarding pentru această pagină
                completePageOnboarding(page);
            }
        }
    };

    // Funcție pentru finalizarea onboarding-ului unei pagini
    const completePageOnboarding = (page, skip = false) => {
        if (!skip) {
            const onboardingStepKey = `onboarding_page_${page.pageIndex}_completed`;
            localStorage.setItem(onboardingStepKey, 'true');
            log(`[Completion] Onboarding pentru pagina ${page.pageIndex} finalizat.`);
        } else {
            log(`[Skip] Pagina ${page.pageIndex} este deja completată și a fost sărită.`);
        }

        // Treci la următoarea pagină
        const nextPageIndex = currentPageIndex + 1;
        log(`[Navigation] Trec la pagina index ${nextPageIndex}`);
        setCurrentPageIndex(nextPageIndex);
        localStorage.setItem('onboarding_currentPageIndex', nextPageIndex);

        // Resetează indexul pasului curent și indexul mesajului
        setCurrentStepIndex(0);
        setCurrentMessageIndex(0);
        localStorage.setItem('onboarding_currentStepIndex', '0');
        localStorage.setItem('onboarding_currentMessageIndex', '0');
    };

    return null;
};

export default Onboarding3;